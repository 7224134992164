import { doc, onSnapshot } from "firebase/firestore";
import { graphql, Link, navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { FirebaseContext } from "../../components/Firebase";
import LoginFirst from "../../components/loginfirst";
import Seo from "../../components/seo";
import { Button, ContentBg, ContentBox } from "../../components/ui";
import ScheduleRow from "../../components/wc2022/schedule-row";
import StyledSchedule from "../../components/wc2022/styled-schedule";

const StyledHeader = styled.h1`
  display: flex;
  a {
    display: flex;
    text-decoration: none;
    margin-left: 1rem;
    font-size: 1.2rem;
    background: #ddd;
    border-radius: 50%;
    width: 2.3rem;
    height: 2.3rem;
    line-height: 2.3rem;
    justify-content: space-around;
    &:hover {
      background: #ccc;
    }
  }
`;

const HeaderOptions = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProfileSection = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  img {
    margin: 0 1rem 0 0;
    border-radius: 10px;
    box-shadow: 1px 1px 3px orange;
    width: 5rem;
  }
  /* @media screen and (max-width: 768px) {
    flex-direction: column;
  } */
`;

const Schedule = props => {
  const { location } = props;
  const { firebase, user } = useContext(FirebaseContext);
  const [schedule, setSchedule] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [profile, setProfile] = useState({});
  const [secondaryProfile, setSecondaryProfile] = useState({});
  const [prefilling, setPrefilling] = useState(false);

  const primaryView = !location.search; // < probably not the most robust, follow up later @todo
  const profileUid = location.search
    ? location.search.substring(1)
    : user && user.userId;

  const intl = useIntl();

  useEffect(() => {
    if (!firebase) {
      navigate("/");
    } else {
      // fetch predictions once, regardless of own vs other's profile:
      firebase.getSchedule().then(schedule => setSchedule(schedule));

      if (!primaryView) {
        firebase.getProfileByUid(profileUid).then(profile => {
          setSecondaryProfile(profile);
          firebase.getProfilePredictions(profile.email).then(predictions => {
            setPredictions(predictions);
          });
        });
      }

      const unsubscribePredictions = firebase.subscribeToPredictions({
        email: primaryView ? user.email : secondaryProfile.email,
        onSnap: querySnapshot => {
          const snapshotPredictions = {};
          querySnapshot.forEach(doc => {
            const matchData = doc.data();
            snapshotPredictions[matchData.matchNumber] = matchData;
          });
          setPredictions(snapshotPredictions);
        },
      });

      const unsubscribeProfile = onSnapshot(
        doc(firebase.db, "publicProfiles", user.email),
        doc => {
          setProfile(doc.data());
        }
      );

      return () => {
        if (unsubscribePredictions && unsubscribeProfile) {
          unsubscribePredictions();
          unsubscribeProfile();
        }
      };
    }
  }, []);

  const prefillPredictions = async e => {
    e.preventDefault();
    if (confirm(intl.formatMessage({ id: "wc2022_are_you_sure" }))) {
      setPrefilling(true);
      firebase.prefillPredictions().catch(e => {
        alert(
          "Something didn't go as expected. Reloading the page so you can try again."
        );
        window.location.reload();
      });
    }
  };

  // const schedule = data.allSchedule.edges;
  const filter = location.hash
    ? decodeURIComponent(location.hash.substring(1))
    : null;

  let currentRound = 0;

  return (
    <ContentBg>
      <ContentBox fullWidth>
        <Seo title="World Cup Poule 2022" />
        <>
          <StyledHeader>
            World Cup Poule 2022 - <FormattedMessage id="wc2022_predictions" />
            {filter ? (
              <>
                {", filter: "}
                {filter.length === 1 ? (
                  <>
                    <FormattedMessage id="wc2022_group" />
                    {` ${filter}`}
                  </>
                ) : (
                  <FormattedMessage
                    id={`wc2022_${filter.toLowerCase().split(" ")[0]}`}
                  />
                )}
                <Link to="./">❌</Link>
              </>
            ) : (
              ""
            )}
          </StyledHeader>
          {!primaryView && !!profile && (
            <ProfileSection>
              {secondaryProfile.photoURL && (
                <img
                  src={secondaryProfile.photoURL}
                  alt={secondaryProfile.name}
                  referrerPolicy="no-referrer"
                />
              )}
              <div>
                <h2>{secondaryProfile.name}</h2>
                <p>
                  <FormattedMessage id="wc2022_points" />:
                  {` ${secondaryProfile.totalPoints || 0}`}
                  {" | "}
                  <Link to="/worldcup2022/standings">
                    <FormattedMessage id="wc2022_standings" />
                  </Link>
                </p>
              </div>
            </ProfileSection>
          )}
          {/* <FormattedMessage id="wc2022_points" />: {profile.totalPoints || 0} */}
        </>

        {user ? (
          <>
            {primaryView && (
              <HeaderOptions>
                {!prefilling ? (
                  <Button onClick={prefillPredictions}>
                    <FormattedMessage id="wc2022_fill_in_my_scores" />
                  </Button>
                ) : (
                  <span>
                    <FormattedMessage id="wc2022_hang_on" />
                  </span>
                )}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    navigate(`${location.pathname}#incomplete`);
                  }}
                >
                  Filter out the ones you've already completed
                </a>
              </HeaderOptions>
            )}
            <StyledSchedule primaryView={primaryView}>
              <li className="header">
                <strong>What the colors mean:</strong>
              </li>
              <li className="header">
                <div className="matchinfo">
                  <div className="matchnumber" style={{ background: "red" }}>
                    #
                  </div>
                  = You haven't filled out this match yet.
                </div>
                <div className="matchinfo">
                  <div className="matchnumber" style={{ background: "orange" }}>
                    #
                  </div>
                  = You've partially filled this out.
                </div>
                <div className="matchinfo">
                  <div className="matchnumber" style={{ background: "green" }}>
                    #
                  </div>
                  = You're all set!
                </div>
              </li>
              <li className="header warning">
                <strong>
                  You have until November 19th to fill EVERYTHING out!
                </strong>
              </li>
              {/* <li className="header">
              <div>
                <FormattedMessage id="wc2022_gametime" />
              </div>
              <div>
                <FormattedMessage id="wc2022_your_prediction" />
              </div>
              <div>Actual</div>
              <div>
                <FormattedMessage id="wc2022_points" />
              </div>
            </li> */}
              {schedule
                .filter(match => {
                  if (!filter || !isNaN(Number(filter))) return match;
                  if (filter === "incomplete") {
                    return (
                      predictions[match.MatchNumber] &&
                      predictions[match.MatchNumber].scoreCompleteness !== 100
                    );
                  }
                  if (filter.length === 1) {
                    return match.Group === filter;
                  } else {
                    return (
                      match.HomeTeam === filter || match.AwayTeam === filter
                    );
                  }
                })
                .map((match, idx) => {
                  const prediction = predictions[match.MatchNumber] || {};
                  if (match.RoundNumber !== currentRound) {
                    currentRound = match.RoundNumber;
                    return (
                      <>
                        <li key={`r${prediction.matchNumber}`}>
                          <h4>
                            <FormattedMessage
                              id={`wc2022_round${match.RoundNumber}`}
                            />
                          </h4>
                        </li>
                        <ScheduleRow
                          match={match}
                          idx={idx}
                          key={idx}
                          filter={filter}
                          prediction={prediction}
                          primaryView={primaryView}
                          location={location}
                        />
                      </>
                    );
                  } else {
                    return (
                      <ScheduleRow
                        match={match}
                        idx={idx}
                        key={idx}
                        filter={filter}
                        prediction={prediction}
                        primaryView={primaryView}
                        location={location}
                      />
                    );
                  }
                })}
            </StyledSchedule>
          </>
        ) : (
          <LoginFirst />
        )}
      </ContentBox>
    </ContentBg>
  );
};

export default Schedule;
