import React from "react";
import styled from "styled-components";

const StyledSchedule = styled.ol`
  display: flex;
  flex-direction: column;
  margin-left: 0;
  li {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    list-style: none;
    border-bottom: 1px dotted #ccc;
    flex: 1;
    /* cursor: ${props => (props.primaryview ? "pointer" : "default")}; */
    // admin area
    &.admin {
      background: red;
      padding: 0.3rem 0;
      min-height: auto;
      border: 1px inset white;
      span,
      select,
      button {
        margin-right: 0.3rem;
      }
    }
    &:nth-child(even) {
      background-color: #eee;
    }
    &:hover {
      background-color: #ddd;
    }
    @media screen and (max-width: 768px) {
      padding: 0.3rem 0;
      flex-direction: column;
    }
    &.header {
      &:hover {
        background-color: transparent;
        cursor: default;
      }
      &.warning {
        min-height: 3rem;
        align-items: center;
        justify-content: space-around;
        font-style: italic;
      }
      div {
        font-weight: bold;
        min-height: 2rem;
        &.matchinfo {
          position: relative;
        }
      }
    }
    > div {
      /* display: inline-flex; */
      padding-right: 1%;
      min-height: 5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // date
      &.matchinfo {
        flex: 1;
        padding: 0 2.5rem;
        color: #333;
        div.matchnumber {
          width: 2rem !important;
          height: 2rem;
          line-height: 2rem;
          border-radius: 0 0 50%;
          background: white;
          border: 1px solid #ccc;
          border-left: 0;
          border-top: 0;
          text-align: center;
          padding: 0;
          margin-right: 1rem;
          color: white;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &.match {
        flex: 4;
        flex-direction: column;
        > div {
          display: flex;
          flex: 1;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px dotted #ccc;
          .score,
          .answer {
            background: white;
            padding: 0.5rem 0.25rem;
            border-radius: 0.25rem;
            display: inline-block;
            width: 6rem;
            height: 1rem;
            text-align: center;
            border: 1px solid #ddd;
          }
          span select {
            width: 12rem;
          }
        }
        .vs {
          display: inline-block;
          padding: 0 0.5rem;
        }
      }
      &.actual {
        flex: 1;
        flex-direction: column;
        > div {
          height: 1rem;
          padding: 0.75rem 0;
        }
      }
      // points
      &.points {
        flex: 1;
        > div {
          height: 1rem;
          padding: 0.75rem 0;
        }
      }
      // icon
      &.icon {
        width: 4%;
      }

      /* MOBILE */
      @media screen and (max-width: 768px) {
        min-height: auto;
        &.matchinfo {
          display: flex;
          min-height: 2rem;
        }
        // match
        &.match {
          flex: 4;
          > div span:nth-child(1) {
            flex: 3;
          }
          > div span:nth-child(2) {
            flex: 1;
          }
        }
        // actual
        &.actual {
          flex: 1;
        }
        // points
        &.points {
          flex: 1;
        }
      }
    }
    .question {
      font-style: italic;
      form {
        display: flex;
      }
    }
  }
  h4 {
    padding: 0.5rem;
    background-color: #081782;
    color: white;
    border-bottom: 2px outset #333;
    margin-top: 1rem;
    flex: 1;
  }
`;

export default StyledSchedule;
