import { navigate } from "gatsby";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { FirebaseContext } from "../../components/Firebase";
import { Button, Input } from "../ui";
import AnswerInput from "./answer-input";
import Countries from "./countries.json";

const TextInput = styled(Input)`
  margin-right: 0.5rem;
`;

const ScheduleRow = ({
  match,
  idx,
  filter,
  edit,
  prediction,
  primaryView,
  location,
}) => {
  const intl = useIntl();
  const { firebase, user } = useContext(FirebaseContext);
  const editMode = true;

  const {
    MatchNumber,
    RoundNumber,
    DateUtc,
    Location,
    Group,
    HomeTeam,
    AwayTeam,
    HomeTeamScore,
    AwayTeamScore,
    CorrectAnswer,
    stats: { homeWins, awayWins, draws } = {},
  } = match;

  const updatePrediction = async (key, value) => {
    const result = await firebase.updatePrediction(MatchNumber, key, value);
    return result;
  };

  const updateGoldenBoot = async e => {
    e.preventDefault();
    const result = await firebase.updatePrediction(
      66,
      "answer",
      e.target.answer.value
    );
    return result;
  };

  const submitScore = async e => {
    e.preventDefault();
    const result = await firebase.submitScore({
      matchNumber: MatchNumber,
      homeScore: e.target.homeScore.value,
      awayScore: e.target.awayScore.value,
      correctAnswer: e.target.correctAnswer.value,
    });
    return result;
  };

  const filterSchedule = (e, filter) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    console.log(filter);
    navigate(`${location.pathname}#${filter}`);
  };

  const showPredictedScore = () => {
    const home =
      typeof prediction.homePrediction === "undefined"
        ? "-"
        : prediction.homePrediction;
    const away =
      typeof prediction.awayPrediction === "undefined"
        ? "-"
        : prediction.awayPrediction;
    return `${home} : ${away}`;
  };

  const fetchCountryList = matchId => {
    let groups = matchId.replace(/[0-9]/g, "");
    if (groups === "w" || groups === "l") {
      groups = "abcdefgh";
    }
    const list = [];
    list.push(`wc2022_${matchId}`);
    groups.split("").forEach(group => {
      Countries[group].forEach(country => {
        list.push(`wc2022_${country}`);
      });
    });
    return list;
  };

  return (
    <>
      <li
        key={MatchNumber}
        title={`Round ${RoundNumber}, Match #${MatchNumber}, played at ${Location}`}
        primaryview={primaryView}
      >
        <div className="matchinfo">
          <div
            className="matchnumber"
            title={`${intl.formatMessage({
              id: "wc2022_this_is_matchnumber",
            })} ${prediction.scoreCompleteness}%`}
            style={{
              background:
                prediction.scoreCompleteness > 99
                  ? "green"
                  : prediction.scoreCompleteness > 0
                  ? "orange"
                  : "red",
            }}
          >
            {MatchNumber}
          </div>
          {!!DateUtc && (
            <div>
              {intl.formatDate(new Date(DateUtc * 1000), {
                month: "numeric",
                day: "numeric",
              })}{" "}
              <FormattedMessage id="wc2022_gametime_at" />{" "}
              {intl.formatTime(new Date(DateUtc * 1000))}
            </div>
          )}
          {RoundNumber < 4 && (
            <div className="group">
              <a href={`#${Group}`} onClick={e => filterSchedule(e, Group)}>
                <FormattedMessage id="wc2022_group" /> {Group}
              </a>
            </div>
          )}
        </div>

        <div className="match">
          {RoundNumber < 8 && (
            <div>
              {RoundNumber < 4 ? (
                <span>
                  <a
                    href={`#${HomeTeam}`}
                    onClick={e => filterSchedule(e, HomeTeam)}
                  >
                    <FormattedMessage
                      id={`wc2022_${HomeTeam.toLowerCase().split(" ")[0]}`}
                    />
                  </a>
                  <span className="vs"> vs </span>
                  <a
                    href={`#${AwayTeam}`}
                    onClick={e => filterSchedule(e, AwayTeam)}
                  >
                    <FormattedMessage
                      id={`wc2022_${AwayTeam.toLowerCase().split(" ")[0]}`}
                    />
                  </a>
                </span>
              ) : (
                <span>
                  <AnswerInput
                    onSave={updatePrediction}
                    options={fetchCountryList(HomeTeam.toLowerCase())}
                    prediction={prediction.homeCountry}
                    name="homeCountry"
                    matchNumber={prediction.matchNumber}
                  />
                  <span className="vs"> vs </span>
                  <AnswerInput
                    onSave={updatePrediction}
                    options={fetchCountryList(AwayTeam.toLowerCase())}
                    prediction={prediction.awayCountry}
                    name="awayCountry"
                    matchNumber={prediction.matchNumber}
                  />
                </span>
              )}
              {editMode ? (
                <aside>
                  <AnswerInput
                    onSave={updatePrediction}
                    options={["-", 0, 1, 2, 3, 4, 5, 6, 7]}
                    prediction={prediction.homePrediction}
                    name="homePrediction"
                    matchNumber={prediction.matchNumber}
                  />
                  <AnswerInput
                    onSave={updatePrediction}
                    options={["-", 0, 1, 2, 3, 4, 5, 6, 7]}
                    prediction={prediction.awayPrediction}
                    name="awayPrediction"
                    matchNumber={prediction.matchNumber}
                  />
                </aside>
              ) : (
                <span className="score">{showPredictedScore()}</span>
              )}
            </div>
          )}

          <div className="question">
            <span>
              <FormattedMessage id={`wc2022_q${MatchNumber}`} />
            </span>
            {editMode === true ? (
              MatchNumber < 66 ? (
                <AnswerInput
                  onSave={updatePrediction}
                  options={
                    MatchNumber > 64
                      ? fetchCountryList(HomeTeam.toLowerCase())
                      : ["-", "yes", "no"]
                  }
                  prediction={prediction.answer}
                  name="answer"
                  matchNumber={prediction.matchNumber}
                />
              ) : (
                <form onSubmit={updateGoldenBoot}>
                  <TextInput
                    type="text"
                    name="answer"
                    matchNumber={MatchNumber}
                    defaultValue={prediction.answer || ""}
                  />
                  <Button type="submit">Save</Button>
                </form>
              )
            ) : (
              <span className="answer">
                {prediction.answer ? (
                  <FormattedMessage id={prediction.answer} />
                ) : (
                  "-"
                )}
              </span>
            )}
          </div>
        </div>
        {/* Actual -- HIDDEN THIS UNTIL START */}
        {!isNaN(HomeTeamScore) && !isNaN(AwayTeamScore) && (
          <div className="actual">
            <div
              title={`${intl.formatMessage({ id: "wc2022_score" })}: ${
                prediction.pointsResult + prediction.pointsScore
              } ${intl.formatMessage({ id: "wc2022_points" })}`}
            >
              {HomeTeamScore} : {AwayTeamScore}
            </div>
            <div
              title={`${intl.formatMessage({ id: "wc2022_answer" })}: ${
                prediction.pointsAnswer
              } ${intl.formatMessage({
                id: "wc2022_points",
              })}`}
            >
              <FormattedMessage id={CorrectAnswer || "-"} />
            </div>
          </div>
        )}
        {/* Points */}
        {!isNaN(prediction.pointsTotal) && (
          <div className="points">
            {prediction.pointsTotal} <FormattedMessage id="wc2022_points" />
          </div>
        )}
      </li>
      {/* ADMIN ONLY */}
      {user && user.userId === process.env.GATSBY_FIREBASE_ADMIN_UID && (
        <li className="admin">
          <form onSubmit={submitScore}>
            <AnswerInput
              options={["-", 0, 1, 2, 3, 4, 5, 6, 7]}
              prediction={HomeTeamScore}
              name="homeScore"
              matchNumber={prediction.matchNumber}
              title="Home"
            />
            <AnswerInput
              options={["-", 0, 1, 2, 3, 4, 5, 6, 7]}
              prediction={AwayTeamScore}
              name="awayScore"
              matchNumber={prediction.matchNumber}
              title="Away"
            />
            <AnswerInput
              options={
                MatchNumber > 64
                  ? fetchCountryList(HomeTeam.toLowerCase())
                  : ["-", "yes", "no"]
              }
              prediction={CorrectAnswer}
              name="correctAnswer"
              matchNumber={prediction.matchNumber}
            />
            <Button type="submit">OK</Button>
          </form>
        </li>
      )}
    </>
  );
};

export default ScheduleRow;
