import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";

import { Select } from "../ui";

const AnswerSelect = styled(Select)`
  width: ${props => (props.name === "answer" ? "5.5rem" : "2.66666667rem")};
  height: 2rem;
  margin-left: 0.25rem;
  text-align: center;
`;

const AnswerInput = props => {
  const [answer, setAnswer] = useState("");
  const [updating, isUpdating] = useState(false);

  const { onSave, options, prediction, name, matchNumber } = props;
  useEffect(() => {
    setAnswer(prediction);
  }, [prediction]);

  const intl = useIntl();

  const updateAnswer = async e => {
    // save to local state (cause of React logic)
    let { value } = e.target;
    if (!isNaN(parseInt(value, 10))) {
      value = parseInt(value, 10);
    }
    setAnswer(value);
    if (onSave) {
      isUpdating(true);
      onSave(name, value)
        .then(result => {
          isUpdating(false);
        })
        .catch(() => {
          alert(
            "Saving failed. Try again. If this keeps happening, contact Mark."
          );
          isUpdating(false);
        });
    }
  };

  return (
    <AnswerSelect
      name={name}
      value={answer}
      onChange={updateAnswer}
      disabled={updating}
      title={name}
    >
      {options.map(option => (
        <option value={option} key={option}>
          {isNaN(option) ? intl.formatMessage({ id: option }) : option}
        </option>
      ))}
    </AnswerSelect>
  );
};

export default AnswerInput;
